export const contentfulContentTypes = {
  Entry: 'ContentfulEntry',
  PageUnion: 'PageUnion',
  LandingPage: 'ContentfulLandingPage',
  CampaignPage: 'ContentfulCampaign',
  ContentfulProductPage: 'ContentfulProduct',
  AdvancedPage: 'ContentfulAdvancedPage',
  ArticlePage: 'ContentfulArticlePage',
  RecipePage: 'ContentfulRecipePage',
  Navigation: 'ContentfulNavigation',
  NavigationElement: 'ContentfulNavigationElement',
  LinkBlockModule: 'ContentfulLinkBlockModule',
  ImageModule: 'ContentfulImageModule',
  VideoModule: 'ContentfulVideoModule',
  ProductSliderModule: 'ContentfulProductSliderModule',
  FeaturedProductModule: 'ContentfulFeaturedProductModule',
  LatestArticlesModule: 'ContentfulLatestArticlesModule',
  ContactPersonModule: 'ContentfulContactPersonModule',
  HeroImageModule: 'ContentfulHeroImageModule',
  AccordionModule: 'ContentfulAccordionModule',
  ContentListModule: 'ContentfulContentListModule',
  MediaGalleryModule: 'ContentfulMediaGalleryModule',
  LeadTextModule: 'ContentfulLeadTextModule',
  TableModule: 'ContentfulTableModule',
  ButtonLinkModule: 'ContentfulButtonLinkModule',
  SocialMediaElementModule: 'ContentfulSocialMediaElement',
  PreparationSection: 'ContentfulPreparationSection',
  PreparationStep: 'ContentfulPreparationStep',
  ProductReference: 'ContentfulProductReference',
  Channel: 'ContentfulChannel',
  Ingredient: 'ContentfulIngredient',
  AssetModule: 'ContentfulAsset',
  SEOData: 'ContentfulSEOData',
  Category: 'ContentfulCategory',
  Brand: 'ContentfulBrand',
  TextBlock: 'ContentfulTextBlock',
  Table: 'ContentfulTable',
  RowStructure: 'ContentfulRowStructure',
  ColumnStructure: 'ContentfulColumnStructure',
  CustobarSubscription: 'ContentfulCustobarSubscription',
  FormModule: 'ContentfulFormModule',
  SearchModule: 'ContentfulSearchModule',
  InspirationalAreaModule: 'ContentfulInspirationalAreaModule',
  PopUpModule: 'ContentfulPopUpModule',
  AnalyticsModule: 'ContentfulAnalyticsModule',
  Theme: 'ContentfulTheme',
  IdentifierModule: 'ContentfulIdentifierModule',
  AudioModule: 'ContentfulAudioModule',
  IFrameModule: 'ContentfulIFrameModule',
  InstagramFeedModule: 'ContentfulInstagramFeedModule',
  EventCalendarModule: 'ContentfulEventCalendarModule',
  Event: 'ContentfulEvent',
};

export const magentoContentTypes = {
  Product: 'MagentoProduct',
  Attribute: 'MagentoAttribute',
};

export const secondaryThemeTypes = {
  AnoraPurple: 'anoraPurple',
  AnoraYellow: 'anoraYellow',
  AnoraDarkBlue: 'anoraDarkBlue',
  AnoraWhite: 'anoraWhite',
  AnoraLightBlue: 'anoraLightBlue',
  AnoraGreen: 'anoraGreen',
  AnoraTransparent: 'anoraTransparent',
};

export const searchTypes = {
  AnoraGroupGeneralSearch: 'anoraGroupGeneralSearch',
};

export const messageTypes = {
  Success: 'Success',
  Warning: 'Warning',
  Error: 'Error',
  Info: 'Info',
};

export const siteIds = {
  Viinimaa: 'viinimaa',
  Folkofolk: 'folkofolk',
  AnoraPro: 'anoraPro',
  Bibendum: 'bibendum',
  Blossa: 'blossa',
  PhilipsonSoderberg: 'philipson-soderberg',
  Valhalla: 'valhalla',
  Gammeldansk: 'gammeldansk',
  Wennerco: 'wennerco',
  Koskenkorva: 'koskenkorva',
  OpAnderson: 'opanderson',
  Skagerrak: 'skagerrak',
  WineworldFinland: 'wineworld-fi',
  LinieAquavit: 'linieAquavit',
  Socialwines: 'socialwines',
};

export const fofNavigationTitle = {
  wines: 'Wines',
  spirits: 'Spirits',
};

export const fofNavigationBreakpoint = {
  Breakpoint: '1600',
};
export const viinimaaNavigationBreakpoint = {
  Breakpoint: '1345',
};
export const blossaNavigationBreakpoint = {
  Breakpoint: '1140',
};
export const gammeldanskNavigationBreakpoint = {
  Breakpoint: '900',
};

const getBreakpoints = () => {
  return {
    xs: 0,
    sm: 600,
    md: 840,
    lg: 1280,
    xl: 1920,
  };
};

export const breakPoints = getBreakpoints();

const getContentPageMaxWidth = () => {
  return 'md';
};

export const contentPageMaxWidth = getContentPageMaxWidth();

export const productDefaultImage = {
  Viinimaa: 'viinimaa/labels/viinimaa-kuva-tulossa_oubmmk.png',
  Folkofolk: 'folkofolk/labels/Image-missing.png',
};
export const awards = {
  Viinimaa: [
    'Vuoden Viinit kunniamaininta',
    'Vuoden Viinit hopeamitali',
    'Vuoden Viinit pronssimitali',
    'Vuoden Viinit kultamitali',
    'Palkittu',
  ],
  Folkofolk: ['Prisbelönt'],
};

export const siteMaintenanceMode = {
  ActiveMode: ['Activate Maintenance Mode'],
};

export const subscribeNewsletterPage = {
  Folkofolk: '/nyhetsbrev',
  Viinimaa: '/uutiskirje',
  AnoraPro: 'https://anora-group-plc.campaign.playable.com/horeca-uutiskirjeen-tilaus-lomake-042024',
  ViinimaaRedirectURL: '/kiitos-uutiskirjeen-tilauksesta',
  FolkofolkRedirectURL: '/tack-nyhetsbrev',
};

export const anoraProUnprotectedPages = {
  login: '/kirjaudu',
  registration: '/hae-tunnuksia',
  thankYouForRegistering: '/kiitos-rekisteroitymisesta',
  termsOfUse: '/www-sivuston-kayttoehdot',
  privacy: '/tietosuojailmoitus',
  contactInformation: '/ravintolamyynnin-yhteystiedot',
  contactForm: '/yhteydenottopyynto',
};
