import React, { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import { get, filter, isEqual, isNil } from 'lodash';
import { useStaticQuery, graphql } from 'gatsby';

const ContentContext = createContext({
  getContentData: () => null,
});

export function ContentProvider({ children }) {
  const query = useStaticQuery(graphql`
    query ContentProvider {
      allContentfulArticlePage {
        nodes {
          __typename
          id
          title
          fullPath
          menuTitle
          node_locale
          contentful_id
          shortDescription
          primaryChannel {
            channel
          }
          heroImage {
            imageTitleText
            imageAltText
            cloudinaryImage {
              public_id
            }
          }
          categories {
            fullPath
            title
            id
          }
        }
      }
      allContentfulLandingPage {
        nodes {
          __typename
          id
          fullPath
          menuTitle
          node_locale
          contentful_id
          shortDescription
          primaryChannel {
            channel
          }
          heroImage {
            imageTitleText
            imageAltText
            cloudinaryImage {
              public_id
            }
          }
        }
      }
      allContentfulAdvancedPage {
        nodes {
          __typename
          id
          fullPath
          menuTitle
          title
          node_locale
          contentful_id
          shortDescription
          primaryChannel {
            channel
          }
          heroImage {
            imageTitleText
            imageAltText
            cloudinaryImage {
              public_id
            }
          }
        }
      }
      allContentfulBrand {
        nodes {
          __typename
          id
          title
          fullPath
          menuTitle
          node_locale
          contentful_id
          shortDescription
          primaryChannel {
            channel
          }
          heroImage {
            imageTitleText
            imageAltText
            cloudinaryImage {
              public_id
            }
          }
          categories {
            fullPath
            title
            id
          }
        }
      }
      allContentfulCategory {
        nodes {
          __typename
          id
          title
          alternativePage
          categoryType
          fullPath
          menuTitle
          node_locale
          contentful_id
          shortDescription
          primaryChannel {
            channel
          }
          heroImage {
            imageTitleText
            imageAltText
            cloudinaryImage {
              public_id
            }
          }
        }
      }
      allContentfulRecipePage {
        nodes {
          __typename
          id
          title
          fullPath
          menuTitle
          node_locale
          contentful_id
          shortDescription
          publishingDate
          primaryChannel {
            channel
          }
          heroImage {
            imageTitleText
            imageAltText
            cloudinaryImage {
              public_id
            }
          }
          categories {
            fullPath
            title
            id
          }
        }
      }
      allMagentoProduct {
        nodes {
          __typename
          id
          node_locale
          sku
          name
          fullPath
          shortDescription
          primaryChannel {
            channel
          }
          heroImage {
            imageTitleText
            imageAltText
            cloudinaryImage {
              public_id
            }
          }
          brands {
            id
            title
            fullPath
          }
          categories {
            id
            title
            fullPath
          }

          attributes {
            ...ProductAttributesFragment
          }
        }
      }
    }
  `);

  const contextValue = useMemo(() => {
    const getContentData = channel => {
      const articlePageNodes = get(query, 'allContentfulArticlePage.nodes');
      const landingPageNodes = get(query, 'allContentfulLandingPage.nodes');
      const advancedPageNodes = get(query, 'allContentfulAdvancedPage.nodes');
      const brandNodes = get(query, 'allContentfulBrand.nodes');
      const recipePageNodes = get(query, 'allContentfulRecipePage.nodes');
      const productNodes = get(query, 'allMagentoProduct.nodes');
      const categorytNodes = get(query, 'allContentfulCategory.nodes');

      const filterByChannel = nodes => {
        return filter(nodes, node => {
          return isEqual(get(node, 'primaryChannel.channel'), get(channel, 'channel'));
        });
      };

      return {
        articlePages: filterByChannel(articlePageNodes),
        landingPages: filterByChannel(landingPageNodes),
        advancedPages: filterByChannel(advancedPageNodes),
        brands: filterByChannel(brandNodes),
        recipePages: filterByChannel(recipePageNodes),
        products: filterByChannel(productNodes),
        categories: filterByChannel(categorytNodes),
      };
    };

    return {
      getContentData,
    };
  }, [query]);

  return <ContentContext.Provider value={contextValue}>{children}</ContentContext.Provider>;
}

ContentProvider.propTypes = {
  children: PropTypes.node,
};

ContentProvider.defaultProps = {
  children: null,
};

export function useContent() {
  const context = useContext(ContentContext);
  if (isNil(context)) {
    throw new Error('useContent must be used within a ContentProvider');
  }
  return context;
}
