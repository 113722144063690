import axios from 'axios';
import { DateTime } from 'luxon';

import { get, isEmpty, replace, includes, split, each, isNil } from 'lodash';
import { getWindowLocationSearch, isSSR } from './windowUtils';

const getProductBrand = productData => {
  let brand = get(productData, 'brands.0.title');
  if (isEmpty(brand)) {
    brand = get(productData, 'brands.0.menuTitle');
  }
  if (isEmpty(brand)) {
    brand = '';
  }
  return brand;
};

const getProductCategory = productData => {
  let category = get(productData, 'categories.0.title');
  if (isEmpty(category)) {
    category = get(productData, 'categories.0.menuTitle');
  }
  if (isEmpty(category)) {
    category = '';
  }
  return category;
};

const getProductPrice = productData => {
  let price = get(productData, 'attributes.monopoly_price.value');
  price = parseFloat(price).toFixed(2);
  price = replace(price, '.', '');
  if (isEmpty(price)) {
    price = '';
  }
  return price;
};
const getProductReducedPrice = productData => {
  let price = get(productData, 'attributes.monopoly_reduced_price.value', '');
  if (!isEmpty(price)) {
    price = parseFloat(price).toFixed(2);
    price = replace(price, '.', '');
  }
  return price;
};

export const getUtmCampaignFromPath = () => {
  const locationSearch = getWindowLocationSearch();
  let utmCampainID = '';
  if (includes(locationSearch, 'utm_campaign')) {
    const params = split(locationSearch, '&');
    each(params, param => {
      if (includes(param, 'utm_campaign')) {
        utmCampainID = get(split(param, '='), '1', '');
      }
    });
  }
  return utmCampainID;
};

export const createCustobarOrder = async (productData, customerToken) => {
  try {
    const utmCampaignID = getUtmCampaignFromPath();
    return axios.post(`${process.env.GATSBY_ELASTIC_HOST}/orderevents`, [
      {
        customer_token: customerToken,
        product_category: getProductCategory(productData),
        product_id: get(productData, 'sku', ''),
        product_tags: get(productData, 'attributes.sweetness.value', ''),
        product_title: get(productData, 'name', ''),
        product_type: get(productData, 'attributes.packaging_type.value', ''),
        product_price: getProductPrice(productData),
        product_brand: getProductBrand(productData),
        utm_campaign: utmCampaignID,
      },
    ]);
  } catch (error) {
    console.error(error);
  }
  return null;
};

export const pageBrowseEvent = (pathName, productData) => {
  const cstbr = isSSR() ? null : get(window, 'cstbr', []);

  if (isNil(cstbr)) {
    return;
  }
  cstbr.push({
    type: 'BROWSE',
    path: pathName,
    product_id: get(productData, 'sku', null) ? get(productData, 'sku') : '',
  });
};

export const AnoraConversionEvent = productData => {
  const cstbr = isSSR() ? null : get(window, 'cstbr', []);
  if (isNil(cstbr)) {
    return;
  }
  const saleId = DateTime.now().toFormat('ddMMyyHHmmss');

  const price = isEmpty(getProductReducedPrice(productData))
    ? getProductPrice(productData)
    : getProductReducedPrice(productData);
  cstbr.push({
    type: 'ANORA_CONVERSION',
    product_id: get(productData, 'sku', ''),
    sale_id: saleId,
    product_price: price,
  });
};
